import { Logger } from '@vp/ubik-logging'

const IN_FLIGHT_REQUESTS = new Map<string, Promise<any>>()
const IN_FLIGHT_REFRESHES = new Set<string>()
const IN_MEMORY_CACHE = new Map<string, { response: any, expiration: number }>()

export const useHttpRequestCacheWithExpiry = async (url: string, ttlSeconds: number, getResponse: () => Promise<Response>, logger: Logger): Promise<any> => {
  let { response, expiration } = IN_MEMORY_CACHE.get(url) || {} as any

  if (!response) {   // cache is empty for this url
    logger.info(`HTTP request cache miss for ${url}`)
    if (IN_FLIGHT_REQUESTS.has(url)) {
      response = await IN_FLIGHT_REQUESTS.get(url)
    } else {
      const responsePromise = getResponse()
        .then(response => {
          if (response.ok) {
            if (response.status !== 204) {
              return response.json()
            } else { return {} }
          }
          throw new Error(`${response.status} ${response.statusText}`)
        }).then(data => {
          IN_MEMORY_CACHE.set(url, { response: data, expiration: (ttlSeconds * 1000) + Date.now() })
          return data
        }).catch((ex) => {
          logger.error(`Error getting response for url ${url}: ${ex.message}`)
          return {}
        })
        .finally(() => IN_FLIGHT_REQUESTS.delete(url))
      IN_FLIGHT_REQUESTS.set(url, responsePromise)

      response = await responsePromise
    }
  } else if (expiration < Date.now()) {
    logger.info(`HTTP request cache refresh for ${url}`) // serve stale and refresh in background if response exists but is just expired
    if (!IN_FLIGHT_REFRESHES.has(url)) {
      getResponse().then(response => {
        if (response.ok) {
          return response.json().then(data => {
            IN_MEMORY_CACHE.set(url, { response: data, expiration: (ttlSeconds * 1000) + Date.now() })
          })
        }
        throw new Error(`${response.status} ${response.statusText}`)
      })
        .finally(() => IN_FLIGHT_REFRESHES.delete(url))
        .catch(e => {
          logger.error('Error refreshing cache in background', url, e)
        })
    }
    IN_FLIGHT_REFRESHES.add(url)
  } else {
    logger.info(`HTTP request cache hit for ${url}`)
  }
  return response
}

export const clearInMemoryCache = () => {
  IN_MEMORY_CACHE.clear()
  IN_FLIGHT_REFRESHES.clear()
  IN_FLIGHT_REQUESTS.clear()
}
