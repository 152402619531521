import { Logger } from '@vp/ubik-logging'
import { useHttpRequestCacheWithExpiry } from '../helpers/cachingHelpers'
import { getClientSecret } from './secretClient'

const OAUTH_URL = 'https://oauth.cimpress.io/v2/token'

const getResponseFromOauth = async (secretName: string): Promise<Response> => {
  const { clientId, clientSecret } = await getClientSecret(secretName)
  const oauthResponse: any = await fetch(OAUTH_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      grant_type: 'client_credentials',
      client_id: clientId,
      client_secret: clientSecret,
      audience: 'https://api.cimpress.io/'
    })
  })

  return oauthResponse
}

export const getToken = async (secretName: string, logger: Logger):Promise<string> => {
  const data = await useHttpRequestCacheWithExpiry(OAUTH_URL, 600, () => getResponseFromOauth(secretName), logger)
  return data.access_token
}
