import { SecretsManagerClient, GetSecretValueCommand } from '@aws-sdk/client-secrets-manager'

const client = new SecretsManagerClient({ region: 'eu-west-1' })

export const getClientSecret = async (secretName) => {
  const command = new GetSecretValueCommand({ SecretId: secretName })
  const response = await client.send(command)
  try {
    const parsed = JSON.parse(response.SecretString || '{}')
    return {
      clientId: parsed.id,
      clientSecret: parsed.secret
    }
  } catch {
    return {
      clientId: '',
      clientSecret: ''
    }
  }
}
