import { DefaultContentfulBannerWrapperProps } from '@vp/banner-wrapper'
import { useHttpRequestCacheWithExpiry } from '../helpers/cachingHelpers'
import { getToken } from './oauthClient'
import { Logger } from '@vp/ubik-logging'

const MAP_BASE_URL = 'https://map-site-service.campaignautomation.ct.vpsvc.com/'

type MapBanner = {
  id: string;
  properties: {
    localizedPromoCode?: string;
    longLegalText: string;
    shortLegalText: string;
    expirationMessage: string;
    eyebrow: { text?: string };
    headline: { text?: string };
    headline1: { text?: string };
    headline2: { text?: string };
    subhead: { text?: string };
    ctas: { copy: { text: string }; landingPage: string }[];
    ctaLandingPage: string;
    images: {
      url: string;
      breakpoints: {
        [key: string]: string;
      };
    }[];
    style?: {
      backgroundColor?: string;
      textColorMode?: string;
      backgroundLayout?: string;
      height?: string;
      textBoxHorizontalAlign?: string;
    };
  };
}

const standardizeLocale = (locale: string) => {
  const split = locale.split('-')
  split[0] = split[0].toLocaleLowerCase()
  split[1] = split[1].toUpperCase()
  return split.join('-')
}

export const getData = async (url: string, token: string): Promise<Response> => {
  return await fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}
export const getMapTreatments = async (
  secretConfig: { token?: string; secretName?: string },
  locale: string | unknown,
  logger: Logger
): Promise<{ treatments: string[] }> => {
  let url = `${MAP_BASE_URL}api/v2/treatments/live`
  if (typeof locale === 'string' && locale.length) {
    url += `?locale=${standardizeLocale(locale)}`
  }
  if (!secretConfig.token && secretConfig.secretName) {
    secretConfig.token = await getToken(secretConfig.secretName, logger)
  }

  const responseClosure = async () => await getData(url, secretConfig.token || '')
  const data = await useHttpRequestCacheWithExpiry(url, 600, responseClosure, logger)
  if (data?.treatments) return data
  else return { treatments: [] }
}

export const buildMapContentUrl = (baseUrl: string, standardizedLocale: string, treatments: string[], pageId: string, pageZone?: string) => {
  return new URL(
    `/api/v2/placement/content?culture=${standardizedLocale}&${treatments
      .map((treatmentId) => `treatments=${treatmentId}`)
      .join('&')}&pageId=${encodeURIComponent(pageId)}${pageZone ? `&pageZone=${encodeURIComponent(pageZone)}` : ''}`,
    baseUrl
  ).toString()
}

export const getMapContent = async (
  secretConfig: { token?: string; secretName?: string },
  locale: string,
  treatments: string[],
  pageId: string,
  pageZone: string,
  logger: Logger
): Promise<any> => {
  if (!secretConfig.token && secretConfig.secretName) {
    secretConfig.token = await getToken(secretConfig.secretName, logger)
  }

  const standardizedLocale = standardizeLocale(locale)
  const url = buildMapContentUrl(MAP_BASE_URL, standardizedLocale, treatments, pageId, pageZone)
  const responseClosure = async () => await getData(url, secretConfig.token || '')
  return await useHttpRequestCacheWithExpiry(url, 600, responseClosure, logger)
}

export const translateToBannerProps = (mapBanner: MapBanner, logger: Logger): DefaultContentfulBannerWrapperProps[] | any[] | null => {
  if (!mapBanner.properties.images?.length || (!mapBanner.properties.headline1?.text && !mapBanner.properties.headline?.text)) {
    const missingField = !mapBanner.properties.images?.length ? 'images' : 'headline'
    logger.warn(`Map banner is missing required field ${missingField}, returning null`)
    return null
  }

  const imagesByBreakpoint = mapBanner.properties.images[0]?.breakpoints
  return [
    {
      bannerImage: {
        imageSrc: Object.keys(imagesByBreakpoint || {}).map((breakpoint) => {
          return {
            src: imagesByBreakpoint[breakpoint],
            breakpoint: Number(breakpoint),
          }
        }),
      },
      isDoubleBanner: false,
      ctaButton: (mapBanner.properties.ctas || []).map((cta) => {
        return {
          type: 'button',
          skin: 'primary',
          label: cta.copy?.text,
          href: {
            actionId: cta.copy?.text,
            href: cta.landingPage,
          },
        }
      }),
      textContent: {
        title: mapBanner.properties.headline1?.text || mapBanner.properties.headline?.text,
        footnote: mapBanner.properties.shortLegalText,
        subtitle: mapBanner.properties.headline2?.text,
        description: mapBanner.properties.subhead?.text,
        offerEnding: mapBanner.properties.expirationMessage,
        promocode: mapBanner.properties.localizedPromoCode,
      },
      layout: {
        height: mapBanner.properties.style?.height || 'standard',
        variant: mapBanner.properties.style?.backgroundLayout?.toLowerCase() === 'fullwidthimage' ? 'full-width-image' : 'card',
        imageFocalPoint: 'center center',
        textBoxHorizontalAlign: mapBanner.properties.style?.textBoxHorizontalAlign || 'left',
        hideImageOnExtraSmall: true,
        bgc: mapBanner.properties.style?.backgroundColor || 'warm-white',
        darkMode: mapBanner.properties.style?.textColorMode?.toLowerCase() === 'dark',
        backgroundLayout: mapBanner.properties.style?.backgroundLayout,
      },
    },
  ]
}
